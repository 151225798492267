import React, {useState} from "react";
import './service.css';
import { Link } from "react-router-dom";
import backgroundimage from '../../Assets/images/background.webp';
import { ChildList } from "../../Api/pagedata";

export default function Services() {
    const [ChildPage, setChildPage] = useState({ childpage: [] });
    return (
        <section className="serviceshome" style={{ backgroundImage: `url(${backgroundimage})` }}>
            <div className="container">
                <div className="serviceheaidng">
                    <h2>Our Services</h2>
                    <p>Transform your photos into perfection with our expert photo editing services.</p>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <ChildList currentpageurl={'services'} onFetch={setChildPage} />
                    {ChildPage.childpage.map((pages) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6" key={pages.id}>
                            <div className="servicegrid">
                                <Link to={'/services/'+pages.url+'/'}>
                                    <div className="servicegridimg">
                                        <img src={pages.image} alt={pages.title} />
                                    </div>
                                    <div className="servicegridtitle">
                                        <h2>{pages.title}</h2>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
