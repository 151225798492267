import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonBox = ({
  imageHeight = 150,
  titleWidth = '60%',
  contentCount = 3,
  buttonWidth = '0%',
  priceWidth = '50%',
}) => (
  <div className="skeleton-box" style={styles.box}>
    <Skeleton height={imageHeight} style={styles.image} />
  </div>
);

const SkeletonLoader = ({ count = 5 }) => {
  let columnClass = '';
  switch (count) {
    case 4:
      columnClass = 'col-lg-3';
      break;
    case 3:
      columnClass = 'col-lg-4';
      break;
    case 2:
      columnClass = 'col-lg-6';
      break;
    case 1:
      columnClass = 'col-lg-12';
      break;
    default:
      columnClass = 'col-lg-3'; // Default case, adjust as needed
      break;
  }
  return (
    <div className="row skeleton-loader" style={styles.container}>
      {[...Array(count)].map((_, index) => (
        <div key={index} className={columnClass}>
          <SkeletonBox
            imageHeight={300}
          />
        </div>
      ))}
    </div>
  );
};

const styles = {
  box: {
    backgroundColor: '#fff',
  },
  image: {
    marginBottom: '15px',
  },
};

export default SkeletonLoader;