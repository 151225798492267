import React, {useState} from "react";
import './teams.css';
import { TeamList } from "../../Api/teams";

export default function Teams() {
    const [Teamdata, setTeams] = useState([]);
    return (
    <>
    <section className="teams">
        <div className="container">
            <div className="teamsheaidng">
                <h2>Your Team at PixelWipe</h2>
                <p>Say hello to the Pixel Perfectionists making every shot shine.</p>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <TeamList onFetch={setTeams} />
                {Teamdata.map((team) => (
                    <div className="col-lg-3" key={team.id}>
                        <div className="teamsgrid">
                            <div className="teamsimagearea">
                                <img src={team.image} alt={team.name} />
                            </div>
                            <div className="teamsinfo">
                                <h2>{team.name}</h2>
                                <p>{team.designation}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
    </>
    );
}
