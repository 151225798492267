import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './Assets/css/style.css'
import './Assets/css/materialdesignicons.min.css'
import Index from "./Page/Home";
import Loader from "./Components/Navbar/loader";
import Contactus from "./Page/ContactUs";
import Services from "./Page/Services";
import Portfolio from "./Page/Portfolio/index";
import PortfolioDetails from "./Page/Portfolio/portfoliodetails";

function App() {
  let [loading, setLoading] = useState(true)
  useEffect(() => {
    let timeOut = setTimeout(() => setLoading(false), 600);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  if (loading) {
      return <Loader/>
  }
  return (
    <Router>
      <div className="App">
        <Routes>
            <Route path="/" element={<Index/>}/>
            <Route path="/contact-us" element={<Contactus/>}/>
            <Route path="/services/:slug" element={<Services/>}/>
            <Route path="/portfolio" element={<Portfolio/>}/>
            <Route path="/portfolio/:slug" element={<PortfolioDetails/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
