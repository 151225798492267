import React, {useState} from "react";
import Navbar from "../../Components/Navbar/navbar";
import ScrollTop from "../../Components/Navbar/scrollTop";
import Footer from "../../Components/Footer/footer";
import Slider from "../../Components/Slider/slider";
import Services from "../../Components/Services/service";
import Instagram from "../../Components/InstagramFeed/instagram";
import aboutus from '../../Assets/images/About.webp';
import WorkProcess from '../../Components/WorkProcess/workprocess';
import Teams from '../../Components/Teams/teams';
import './home.css';
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";

export default function Index(){
    const [PageMetas, setPageMeta] = useState({});
    return(
        <>
        <PageMeta currentpageurl={'home'} onFetch={setPageMeta} />
        <Helmet>
            <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Home | PixelWipe'}</title>
            <meta name="description" content={PageMetas.metadescription || ''} />
            <meta name="keywords" content={PageMetas.metakeyword || ''} />
        </Helmet>
        <Navbar navClass="navbar-nav navbar-nav-link" socialClass="list-unstyled mb-0 mt-2 mt-sm-0 social-icon" navDark={true}/>
        <Slider/>
        <section className="aboutussection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12 col-12">
                        <div className="aboutcontentleft">
                            <h2>PixelWipe Photo Editing</h2>
                            <p>Are you searching for a premier photo editing company that excels in delivering high-quality image retouching services? Look no further! Our team of experts specializes in photo retouching, ensuring every image is perfected to meet your needs. Whether you're a professional photographer, an e-commerce business, or an individual wanting to enhance personal photos, our photo retouch online services provide the convenience and quality you deserve. Our skilled professionals use the latest techniques to retouch images, removing imperfections, enhancing colors, and bringing out the best in every shot. With years of experience in the industry, we pride ourselves on being a trusted photo editing company dedicated to exceeding client expectations. From subtle touch-ups to complete transformations, our image retouching services cater to a wide range of requirements. Experience the difference with our comprehensive photo retouching solutions, designed to elevate your photos to a professional standard.</p>
                            <p>We specialize in offering cutting-edge image editing services on photo masking, portrait retouching, image clipping, digital photo restoration, alpha masking, color masking to our esteemed clients spreading across the globe, especially in the countries like the United States (USA), United Kingdom (UK), Australia, Canada, Dubai, Saudi Arabia, UAE, Germany, Italy, India, Singapore, France, Finland, Ireland, etc.</p>
                            <p>Contact us today to learn more about our photo retouch online services and see how we can help you achieve stunning results.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12 col-12">
                        <div className="aboutusrigtimage">
                            <img src={aboutus} alt="About us" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Services/>
        <Instagram/>
        <WorkProcess/>
        <Teams/>
        <Footer/>
        <ScrollTop/>
        </>
    )
}