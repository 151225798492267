import React from "react";
import './workprocess.css';
import { Link } from "react-router-dom";
import backgroundimage from '../../Assets/images/background.webp';
import process1 from '../../Assets/images/1-service.svg';
import process2 from '../../Assets/images/follow.svg';
import process3 from '../../Assets/images/we-guarantee.svg';
import process4 from '../../Assets/images/24-hours.svg';

export default function WorkProcess() {
    return (
        <section className="workprocess" style={{ backgroundImage: `url(${backgroundimage})` }}>
            <div className="container">
                <div className="workprocessheading">
                    <h2>Photo Retouching Services | Professional Photo Editing</h2>
                    <p>PixelWipe has been the most trusted agency in the online photo retouching business since 2003. Simply upload photos, write your requirements, and we'll take care of the rest. (few corrections in grammar as per US standard)</p>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <div className="workprocessgrid">
                            <div className="imageareaprocess">
                                <img src={process1} alt="image1"/>
                            </div>
                            <div className="processcontent">
                                <p>#1 service in photo editing industry</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <div className="workprocessgrid">
                            <div className="imageareaprocess">
                                <img src={process2} alt="image2"/>
                            </div>
                            <div className="processcontent">
                                <p>Follow your unique photography style</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <div className="workprocessgrid">
                            <div className="imageareaprocess">
                                <img src={process3} alt="image3"/>
                            </div>
                            <div className="processcontent">
                                <p>We guarantee 100% security</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <div className="workprocessgrid">
                            <div className="imageareaprocess">
                                <img src={process4} alt="image4"/>
                            </div>
                            <div className="processcontent">
                                <p>Fast 24 hour turnaround</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="processbuttionarea">
                    <ul>
                        <li><Link to="#">Try for Free</Link></li>
                        <li><Link to="#">Get a Quote</Link></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
