
const APP_URL = "https://pixelwipe.com";

const API_URL = "https://cadmin.pixelwipe.com";

const Apis = {
  PageData: `${API_URL}/api/page-data`,
  PortfolioCategory: `${API_URL}/api/portfolio-category`,
  PortfolioData: `${API_URL}/api/portfolio`,
  ChildPageList: `${API_URL}/api/child-page-list`,
  TeamList: `${API_URL}/api/teams`,
  ServicePortfolioData: `${API_URL}/api/service-portfolio`,
};
export { API_URL, Apis, APP_URL };