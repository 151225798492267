import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './instagram.css';

const InstagramFeed = ({ limit = 6 }) => {
    const [posts, setPosts] = useState([]);
    const accessToken = 'IGQWRNRFhFalh1a3VwN2U4akNqOW9USXUyR211czd2R3dDQzlEZAUdSdTNyVGNaeWlEUnVhZA1RRbGE1UnFERjFvN05kLXJWR18tTXJNZA0pKRFJVeTBtWGFhQUZAqaDl6LUpCYmNnbjF0RVNfX1hvOHZACekMwY0VCSFUZD';
  
    useEffect(() => {
      const fetchInstagramPosts = async () => {
        try {
          const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${accessToken}&limit=${limit}`);
          setPosts(response.data.data);
        } catch (error) {
          console.error('Error fetching Instagram posts', error);
        }
      };
  
      fetchInstagramPosts();
    }, [accessToken, limit]);

  return (
    <section className="instagramfeed">
        <div className="container">
            <div className="serviceheaidng">
                <h2>Instagram Feed</h2>
                <p>Follow <a href="https://www.instagram.com/pixel_wipe/" rel="noreferrer" target='_blank'>@pixel_wipe</a></p>
            </div>
        </div>
        <div className="container">
            <div className="row">
              {posts.map(post => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6">
                    <div key={post.id} className="instagram-post">
                      <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                        <div className="instapostimage">
                          {post.media_type === 'CAROUSEL_ALBUM' && <img src={post.media_url} alt={post.caption} />}
                          {post.media_type === 'IMAGE' && <img src={post.media_url} alt={post.caption} />}
                          {post.media_type === 'VIDEO' && <img src={post.thumbnail_url} alt={post.caption} />}
                        </div>
                        <div className="instafeedurl">
                          <i className='mdi mdi-link'></i>
                        </div>
                      </a>
                    </div>
                </div>
              ))}
            </div>
        </div>
        <div className="container">
          <div className="viewmoreinstabtn"><a href="https://www.instagram.com/pixel_wipe/" rel="noreferrer" target='_blank'><i class="mdi mdi-instagram"></i> View More Feed</a></div>
        </div>
    </section>
  );
};

export default InstagramFeed;
