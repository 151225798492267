import React from "react";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './slider.css';
import banner1 from '../../Assets/images/banner.webp';
import banner2 from '../../Assets/images/banner2.webp';
import banner3 from '../../Assets/images/banner-11.webp';
import banner4 from '../../Assets/images/banner7.webp';
import banner5 from '../../Assets/images/banner-8.webp';
import banner6 from '../../Assets/images/banner-9.webp';
import banner7 from '../../Assets/images/banner-10.webp';

export default function Slider() {
    return (
        <Carousel indicators={false}>
            <Carousel.Item>
                <img className="d-block w-100" src={banner2} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner1} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner3} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner4} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner5} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner6} alt="First slide" />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={banner7} alt="First slide" />
            </Carousel.Item>
        </Carousel>
    );
}
