import React, {useState} from "react";
import { useLocation } from 'react-router-dom';
import "./contact.css";
import Navbar from "../../Components/Navbar/navbar";
import ScrollTop from "../../Components/Navbar/scrollTop";
import Footer from "../../Components/Footer/footer";
import Topgrid from "../../Components/ContactUs/topgrid";
import { PageMeta } from "../../Api/pagedata";
import {Helmet} from "react-helmet";

export default function Contactus(){
    const [PageMetas, setPageMeta] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    return(
        <>
        <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
        <Helmet>
            <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Contact Us | PixelWipe'}</title>
            <meta name="description" content={PageMetas.metadescription || ''} />
            <meta name="keywords" content={PageMetas.metakeyword || ''} />
        </Helmet>
        <Navbar navClass="navbar-nav navbar-nav-link" socialClass="list-unstyled mb-0 mt-2 mt-sm-0 social-icon" navDark={true}/>
        <section className="topheading">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <h2 className="text-center">Contact Us</h2>
                    </div>
                </div>
            </div>
        </section>
        <Topgrid/>
        <Footer/>
        <ScrollTop/>
        </>
    )
}