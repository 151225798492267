import React, {useState} from "react";
import { Link } from "react-router-dom";
import logoLight from '../../Assets/images/main-logo.svg'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";
import { ChildList } from "../../Api/pagedata";

export default function Footer(){
    const [ChildPage, setChildPage] = useState({ childpage: [] });
    return(
        <>
        <footer className="mainfooter">
            <div className="container">
                <div className="footergird">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="footercontent">
                                <div className="footerlogo">
                                    <img src={logoLight} alt="mainlogo" width="250" height="30" />
                                </div>
                                <div className="footercontents">
                                    <p>Special motifs, innovative ideas and creative approaches call for special measures.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footercontent">
                                <div className="footerheading">
                                    <h2>Our Srevices</h2>
                                </div>
                                <div className="footermenu">
                                    <ul>
                                        <ChildList currentpageurl={'services'} onFetch={setChildPage} />
                                        {ChildPage.childpage.map((pages) => (
                                            <li key={pages.id}>
                                                <Link to={'/services/'+pages.url+'/'}>{pages.title}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footercontent">
                                <div className="footerheading">
                                    <h2>Company</h2>
                                </div>
                                <div className="footermenu">
                                    <ul>
                                        <li><Link to="#">Privacy Policy</Link></li>
                                        <li><Link to="#">Terms & Conditions</Link></li>
                                        <li><Link to="#">Refund Policy</Link></li>
                                        <li><Link to="/contact-us/">Contact us</Link></li>
                                        <li><Link to="#">Payments</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footercontent">
                                <div className="footerheading">
                                    <h2>Contact us</h2>
                                </div>
                                <div className="footercontactinfo">
                                    <ul>
                                        <li><FaLocationDot/> Street no 42, madangir, New Delhi 110062</li>
                                        <li><FaPhoneVolume /> <a href="tel:+91 8929321919" rel="noreferrer">+91 8929321919</a></li>
                                        <li><FaEnvelope /> <a href="mailto:info@pixelwipe.com" rel="noreferrer">info@pixelwipe.com</a></li>
                                    </ul>
                                </div>
                                <div className="socialmedialist">
                                    <ul className="list-unstyled mb-0 social-icon">
                                        <li className="list-inline-item"><Link to="#"><i className="mdi mdi-facebook"></i></Link></li>
                                        <li className="list-inline-item"><Link to="https://www.instagram.com/pixel_wipe/" target="_blank"><i className="mdi mdi-instagram"></i></Link></li>
                                        <li className="list-inline-item"><Link to="#"><i className="mdi mdi-linkedin"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <section className="footerbottom">
            <div className="container">
                <p>© PixelWipe. All Rights Reserved, 2024 | Developed by <a href="https://bluedigitaltechnologies.com/" target="_blank" rel="noreferrer">Blue Digital Technologies.</a></p>
            </div>
        </section>
        </>
    )
}