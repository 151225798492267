import { useEffect } from "react";
import axios from "axios";
import { Apis } from './index';

const PageMeta = ({ currentpageurl, onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        if (currentpageurl) {
            const fetchPageMeta = async () => {
                try {
                    const response = await axios.post(Apis.PageData, { url: currentpageurl }, {
                        headers: { 'Authorization': `Bearer ${apiKey}`, 'Content-Type': 'application/json' }
                    });
                    if (onFetch) {
                        onFetch(response.data.pages);
                    }
                } catch (error) {
                    console.error('Error fetching page metadata:', error);
                }
            };
            fetchPageMeta();
        }
    }, [currentpageurl, apiKey, onFetch]);
    return null;
};

const ChildList = ({ currentpageurl, onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        if (currentpageurl) {
            const fetchChildList = async () => {
                try {
                    const response = await axios.post(Apis.ChildPageList, { parent_url: currentpageurl }, {
                        headers: { 'Authorization': `Bearer ${apiKey}`, 'Content-Type': 'application/json' }
                    });
                    if (onFetch) {
                        onFetch(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching child page list:', error);
                }
            };
            fetchChildList();
        }
    }, [currentpageurl, apiKey, onFetch]);
    return null;
};
export { PageMeta, ChildList };