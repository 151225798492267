import { useEffect } from "react";
import axios from "axios";
import { Apis } from './index';

const TeamList = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchTeamList = async () => {
            try {
                const response = await axios.get(Apis.TeamList, {
                    headers: { 'Authorization': `Bearer ${apiKey}`,'Content-Type': 'application/json' }
                });
                if (onFetch) {
                    onFetch(response.data.teams);
                }
            } catch (error) {
                //console.error(error);
            }
        };
        fetchTeamList();
    }, [apiKey, onFetch]);
    return null;
};
export { TeamList };