import React, {useState, useCallback, useEffect} from "react";
import { PageMeta } from "../../Api/pagedata";
import { ServicePorftfolioData } from "../../Api/Portfolio";
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom';
import "./index.css";
import Navbar from "../../Components/Navbar/navbar";
import ScrollTop from "../../Components/Navbar/scrollTop";
import Footer from "../../Components/Footer/footer";
import Sidebar from "../../Components/Services/servicesidebar";
import SkeletonLoader from "../../Components/Skeleton_loader/skeleton";

export default function Newborn(){  
    const [PageMetas, setPageMeta] = useState({});
    const [Portfolios, setPortfolio] = useState({});
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[1] || '';
    const handleFetch = useCallback((data) => {
        setPortfolio(data || []);
        setLoading(false);
    }, []);
    useEffect(() => {
        setLoading(true);
    }, []);
    return(
        <>
        <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
        <Helmet>
            <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Services | PixelWipe'}</title>
            <meta name="description" content={PageMetas.metadescription || ''} />
            <meta name="keywords" content={PageMetas.metakeyword || ''} />
        </Helmet>
        <Navbar navClass="navbar-nav navbar-nav-link" socialClass="list-unstyled mb-0 mt-2 mt-sm-0 social-icon" navDark={true}/>
        <section className="topheading">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <h2 className="text-center">{PageMetas.page_title || ''}</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="servicecontent">
            <div className="container-fluid">
                <div className="width80">
                    <div className="row">
                        <div className="col-lg-3"><Sidebar/></div>
                        <div className="col-lg-9">
                            <div className="servicedatacont">
                                <div className="serviceareacontent" dangerouslySetInnerHTML={{ __html: PageMetas.page_content || '' }} />
                                <div className="servicegallery">
                                    <h2>Our Gallery</h2>
                                    <div className="gallerygrid">
                                        <div className="row">
                                        <ServicePorftfolioData currentpageurl={secondSegment} onFetch={handleFetch} />
                                        {!loading ? (
                                            <>
                                            {Portfolios && Portfolios.length > 0 ? ( Portfolios.map((portfolio) => (
                                                <div className="col-md-4">
                                                    <div className="gallerygridbox">
                                                        <img src={portfolio.image} alt={portfolio.title}/>
                                                    </div>
                                                </div>
                                            ))
                                            ) : (
                                                <p>No gallery available.</p>
                                            )}
                                            </>
                                        ): (
                                            <>
                                            <SkeletonLoader count={3} />
                                            </>
                                        )}
                                        </div>
                                    </div>
                                    {/* <div className="viewmoreinstabtn">
                                        <Link to="#">View All Portfolio</Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}