import { useEffect } from "react";
import axios from "axios";
import { Apis } from './index';

const PortfolioCategory = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchPortfolioCategory = async () => {
            try {
                const response = await axios.get(Apis.PortfolioCategory, {
                    headers: { 'Authorization': `Bearer ${apiKey}`,'Content-Type': 'application/json' }
                });
                if (onFetch) {
                    onFetch(response.data.category);
                }
            } catch (error) {
                //console.error(error);
            }
        };
        fetchPortfolioCategory();
    }, [apiKey, onFetch]);

    return null;
};
const PorftfolioData = ({currentpageurl,onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchPortfolioData = async () => {
            try {
                const response = await axios.post(Apis.PortfolioData, { cat_url: currentpageurl }, {
                    headers: { 'Authorization': `Bearer ${apiKey}`,'Content-Type': 'application/json' }
                });
                if (onFetch) {
                    onFetch(response.data);
                }
            } catch (error) {
                //console.error(error);
            }
        };
        fetchPortfolioData();
    }, [currentpageurl, apiKey, onFetch]);

    return null;
};
const ServicePorftfolioData = ({currentpageurl,onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchPortfolioServiceData = async () => {
            try {
                const response = await axios.post(Apis.ServicePortfolioData, { cat_url: currentpageurl }, {
                    headers: { 'Authorization': `Bearer ${apiKey}`,'Content-Type': 'application/json' }
                });
                if (onFetch) {
                    onFetch(response.data.portfolios);
                }
            } catch (error) {
                //console.error(error);
            }
        };
        fetchPortfolioServiceData();
    }, [currentpageurl, apiKey, onFetch]);

    return null;
};

export { PortfolioCategory, PorftfolioData, ServicePorftfolioData };