import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../../Assets/images/main-logo.svg';
import { FiMenu } from '../../Assets/icons/vander';
import { ChildList } from "../../Api/pagedata";

export default function Navbar({navClass, navDark}){
    let [ navSticky, setNavSticky ] = useState(false)
    let [ dropdown, setDropdown ] = useState(false)
    let [ toggleManu, setToggleManu] = useState(false)
    let location = useLocation()
    useEffect(()=>{
        const scrollHandlar = () =>{
            setNavSticky(window.scrollY > 50)
        }
        const closeDropdown = () =>{
            setDropdown(false)
        }
        window.scrollTo(0, 0);

       window.addEventListener('scroll',scrollHandlar)
       document.addEventListener('mousedown', closeDropdown)
       return()=>{
            window.removeEventListener('scroll',scrollHandlar)
            document.removeEventListener('mousedown', closeDropdown)
       }
    },[location.pathname])
    const [ChildPage, setChildPage] = useState({ childpage: [] });
    return(
        <nav id="navbar" className={`${navSticky ? 'nav-sticky' : ''} navbar navbar-expand-lg navbar-custom navbar-light sticky`}>
    		<div className="container">
                {navDark ? 
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="logo-light-mode" alt=""/>
                    </Link> :
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="l-dark" alt=""/>
                    </Link>
                }
                <button className="navbar-toggler" type="button" onClick={() =>setToggleManu(!toggleManu)}>
                    <FiMenu className="fea icon-md"/>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse" style={{display: toggleManu ?'block' : 'none'}}>
                    <ul id="navbar-navlist" className={navClass}>
                        <li className="nav-item">
                            <Link className="nav-link" activeClass="active"  spy={true} smooth={true} duration={500} to='/'>Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className={`nav-link dropdown-toggle`} to="#" onClick={()=>setDropdown(true)}>Services</Link>
                            <div className={`${dropdown ? 'show' : ''} dropdown-menu rounded m-0`}>
                                <div className="container mx-0 mx-md-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ChildList currentpageurl={'services'} onFetch={setChildPage} />
                                            {ChildPage.childpage.map((pages) => (
                                                <Link className={`dropdown-item`} key={pages.id} to={'/services/'+pages.url+'/'}>{pages.title}</Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" activeClass="active"  spy={true} smooth={true} duration={500} to="/portfolio/">Portfolio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" activeClass="active"  spy={true} smooth={true} duration={500} to="#">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" activeClass="active"  spy={true} smooth={true} duration={500} to="#">Blog</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" activeClass="active"  spy={true} smooth={true} duration={500} to="/contact-us/">Contact Us</Link>
                        </li>
                    </ul>
                </div> 
            </div>
		</nav>
    )
}