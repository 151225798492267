import React from "react";
import './topgrid.css';
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";

export default function TopGrid() {
    return (
        <>
        <section className="contactustoparea">
            <div className="container">
                <div className="contactustop">
                    <h2>We're always eager to hear from you!</h2>
                    <p>Our customer service team is always available to answer your queries or to receive your feedback Fillup the given form to get in touch with us and we'll get back to you within 24 working hours!</p>
                </div>
            </div>
        </section>
        <section className="contactforms">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="contactinfoboxs">
                            <div className="contacticons">
                                <FaLocationDot/>
                            </div>
                            <h3>Address</h3>
                            <p>Street no 42, madangir, New Delhi 110062</p>
                        </div>
                        <div className="contactinfoboxs">
                            <div className="contacticons">
                                <FaPhoneVolume/>
                            </div>
                            <h3>Contact</h3>
                            <p><Link to="tel:+918929321919">+91 8929321919</Link></p>
                        </div>
                        <div className="contactinfoboxs">
                            <div className="contacticons">
                                <FaEnvelope/>
                            </div>
                            <h3>Email</h3>
                            <p><Link to="mailto:info@pixelwipe.com">info@pixelwipe.com</Link></p>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="contactformdta">
                            <h3>Ask us anything here</h3>
                            <div className="contactform">
                                <form action="" method="post">
                                    <div className="form-group">
                                        <label htmlFor="name">Name <span className="required">*</span></label>
                                        <input type="text" className="form-control" id="name" name="name" placeholder="Enter your name"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                        <input type="email" className="form-control" id="email" name="email" placeholder="Enter your email"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Phone Number <span className="required">*</span></label>
                                        <input type="tel" className="form-control" id="phone" name="phone" placeholder="Enter your phone number"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message <span className="required">*</span></label>
                                        <textarea name="message" id="message" className="form-control" rows="5" placeholder="Type your message"></textarea>
                                    </div>
                                    <div className="form-group text-right">
                                        <button type="submit">Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="maparea">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7011.259457670088!2d77.22124043235985!3d28.52078590254586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce19cb2ad42ef%3A0x3dc9f4b481caeca3!2sMadangir%2C%20New%20Delhi%2C%20Delhi%20110062!5e0!3m2!1sen!2sin!4v1721237937501!5m2!1sen!2sin" width="100%" title="map" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
        </>
    );
}
