import React, {useState, useCallback, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import Navbar from "../../Components/Navbar/navbar";
import ScrollTop from "../../Components/Navbar/scrollTop";
import Footer from "../../Components/Footer/footer";
import './portfolio.css';
import { Link } from "react-router-dom";
import { PageMeta } from "../../Api/pagedata";
import { PortfolioCategory, PorftfolioData } from "../../Api/Portfolio";
import {Helmet} from "react-helmet";
import SkeletonLoader from "../../Components/Skeleton_loader/skeleton";

export default function Portfolio(){
    const [PageMetas, setPageMeta] = useState({});
    const [Categories, setCategory] = useState([]);
    const [Portfolio, setPortfolio] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[0] || '';
    const handleFetch = useCallback((data) => {
        setPortfolio(data || []);
        setLoading(false);
    }, []);
    useEffect(() => {
        setLoading(true);
    }, []);
    return(
        <>
        <PageMeta currentpageurl={secondSegment} onFetch={setPageMeta} />
        <Helmet>
            <title>{PageMetas.metatitle ? PageMetas.metatitle : 'Portfolio | PixelWipe'}</title>
            <meta name="description" content={PageMetas.metadescription || ''} />
            <meta name="keywords" content={PageMetas.metakeyword || ''} />
        </Helmet>
        <Navbar navClass="navbar-nav navbar-nav-link" socialClass="list-unstyled mb-0 mt-2 mt-sm-0 social-icon" navDark={true}/>
        <section className="topheading">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <h2 className="text-center">Portfolio</h2>
                    </div>
                </div>
            </div>
        </section>
        <section className="portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="mainportsidebar">
                            <div className="portsidebarheaing">
                                <h2>Our Services</h2>
                            </div>
                            <div className="portfoliolist">
                                <ul>
                                    <PortfolioCategory onFetch={setCategory} />
                                    {Categories.map((Category) => (
                                        <li key={Category.id} className={location.pathname === Category.category_url ? 'active' : ''}><Link to={Category.category_url}>{Category.category_name}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="servicedatacont">
                            <div className="servicegallery">
                                <div className="gallerygrid">
                                    <div className="row">
                                    <PorftfolioData currentpageurl='newborn-retouching' onFetch={handleFetch} />
                                    {!loading ? (
                                        <>
                                    {Portfolio.portfolios && Portfolio.portfolios.length > 0 ? (
                                        Portfolio.portfolios.map((portfolio) => (
                                            <div className="col-md-4">
                                                <div className="portfoliogridbox">
                                                    <img src={portfolio.image} alt={portfolio.title}/>
                                                </div>
                                            </div>
                                         ))
                                        ) : (
                                            <p>No portfolios available.</p>
                                        )} 
                                        </>
                                    ): (
                                            <>
                                            <SkeletonLoader count={3} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        <ScrollTop/>
        </>
    )
}