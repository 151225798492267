import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import './servicesidebar.css';
import { ChildList } from "../../Api/pagedata";

export default function ServiceSidebar() {
    const location = useLocation();
    const [ChildPage, setChildPage] = useState({ childpage: [] });
    return (
        <>
        <div className="mainsidebarservice">
            <div className="servicesidebarheaing">
                <h2>Our Services</h2>
            </div>
            <div className="servicesidebar">
                <ul>
                    <ChildList currentpageurl={'services'} onFetch={setChildPage} />
                    {ChildPage.childpage.map((pages) => (
                        <li key={pages.id} className={location.pathname === '/services/'+pages.url+'/' ? 'active' : ''}>
                            <Link to={'/services/'+pages.url+'/'}>{pages.title}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        </>
    );
}
